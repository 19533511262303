module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-LTY9KK42ET","cookieName":"goforpet-gdpr-analytics","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"367814504653306","cookieName":"goforpet-gdpr-marketing"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Go For Pet","short_name":"Go For Pet","start_url":"/","background_color":"#FFFFFF","theme_color":"#00594E","display":"minimal-ui","icon":"static/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a39ed879c5c77982b8c428a21f12caf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[],"excludePaths":["/",{"regex":"^/pages"},{"regex":"^/articles"},{"regex":"^/categories"}],"height":3,"color":"#00594E"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
